import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "DragNDrop": "Drag and drop or click here to select your 3D file in stl format.",
            "PlzChooseStl": "Please select an STL file.",
            "PlzChooseAPIKey": "Please enter your API key",
            "Warning": "Warning!",
            "ErrorRequest": "Unable to make the estimate, the file may be too complicated or the format is incompatible.",
            "Error": "Error!",
            "Title": "3D printing estimation",
            "Libs": "3D printing model library:",
            "Quality": "Printing quality:",
            "Precise": "Precise (more expensive but more detailed)",
            "Standard": "Standard (balance between price and precision)",
            "Rough": "Rough (cheaper and stronger but less detailed)",
            "Orientation": "Orientation:",
            "RotateX": "Rotate X:",
            "RotateY": "Rotate Y:",
            "RotateZ": "Rotate Z:",
            "Options": "Options:",
            "Speed": "Printer Speed:",
            "Support": "Support:",
            "Infill": "Infill:",
            "Uploading": "Uploading...",
            "Progress": "Analysis in progress",
            "Wait": "Please wait for the request to finish...",
            "Result": "Result:",
            "Time": "Printing time:",
            "Filament": "Filament used:",
            "Price": "Total price (shipping not included):",
            "Calculate": " Calculate",
            "Order": " Order",
            "Yes": "Yes",
            "No": "No",
        }
    },
    fr: {
        translation: {
            "DragNDrop": "Glissez-déposez ou cliquez ici pour sélectionner votre fichier 3D au format stl.",
            "PlzChooseStl": "Veuillez sélectionner un fichier STL.",
            "PlzChooseAPIKey": "Veuillez entrer votre jeton d'API",
            "Warning": "Attention !",
            "ErrorRequest": "Impossible de faire le devis, le fichier est peut être trop compliqué ou le format est incompatible.",
            "Error": "Erreur !",
            "Title": "Devis impression 3D",
            "Libs": "Bibliothèque de modèles d'impression :",
            "Quality": "Qualité d'impression :",
            "Precise": "Précis (plus cher mais plus détaillé)",
            "Standard": "Standard (equilibre entre prix et précision)",
            "Rough": "Grossier (moins cher et plus solide mais moins de détails)",
            "Orientation": "Orientation :",
            "RotateX": "Rotate X :",
            "RotateY": "Rotate Y :",
            "RotateZ": "Rotate Z :",
            "Options": "Options :",
            "Speed": "Printer Speed :",
            "Support": "Support :",
            "Infill": "Infill :",
            "Uploading": "Téléversement...",
            "Progress": "Analyse en cours",
            "Wait": "Veuillez attendre la fin de la requête...",
            "Result": "Résultat :",
            "Time": "Temp d'impression :",
            "Filament": "Filament utilisé :",
            "Price": "Prix total (frais de port non inclu) :",
            "Calculate": " Calculer",
            "Order": " Commander",
            "Yes": "Oui",
            "No": "Non",
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        // lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;